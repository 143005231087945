<template>
  <v-container fluid>
    <confirm ref="confirm"></confirm>

    <div style="width: 100%;" class="my-3 d-flex">
      <v-btn v-if="showPicker" small text @click="goBack" color="primary">
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        Voltar
      </v-btn>
      <v-spacer />
      <v-btn small v-show="!showPicker" @click="createProject" color="primary"
        >Novo projeto <v-icon class="ml-2">mdi-plus</v-icon></v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="projectList"
      v-if="!showPicker"
      @click:row="rowClick"
    >
      <!-- eslint-disable vue/valid-v-slot -->
      <template #item.segmentoNome="{ item }">
        {{ item.segmento && item.segmento.label }}
      </template>
      <!-- eslint-enable vue/valid-v-slot -->
      <!-- eslint-disable vue/valid-v-slot -->
      <!-- <template #item.link="{ item }">
        <div class="actions--cell">
          <v-btn
            color="primary"
            :large="$vuetify.breakpoint.smAndDown"
            icon
            :disabled="!item"
            :to="`/projects/${item._id}`"
          >
            <v-icon>mdi-calculator-variant-outline</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            icon
            :large="$vuetify.breakpoint.smAndDown"
            :to="`/projects/dna/${item._id}`"
            :disabled="!item"
            :class="$vuetify.breakpoint.mdAndUp && 'mx-2'"
          >
            <v-icon>mdi-dna</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            icon
            :disabled="!isReportAvailable(item)"
            :large="$vuetify.breakpoint.smAndDown"
            :to="`/projects/report/${item._id}`"
          >
            <v-icon>mdi-chart-box-outline</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            icon
            :large="$vuetify.breakpoint.smAndDown"
            @click="deleteProject(item._id)"
            :disabled="!item"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template> -->
      <!-- eslint-enable vue/valid-v-slot -->
    </v-data-table>
    <v-sheet v-else class="project-list__selected-project">
      <div class="project-list__selected-project--title">
        <h3 class="headline primary--text align-center">
          {{ (selectedProject && selectedProject.titulo) || "Novo Projeto" }}
        </h3>
      </div>
      <div class="project-list__selected-project--action-selector">
        <div>
          <v-btn
            color="primary"
            text
            x-large
            :to="
              `/projects/${(selectedProject && selectedProject._id) || 'new'}`
            "
          >
            Calculadora
            <v-icon>mdi-calculator-variant-outline</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn
            color="primary"
            text
            x-large
            :to="
              `/projects/dna/${(selectedProject && selectedProject._id) ||
                'new'}`
            "
          >
            DNA
            <v-icon>mdi-dna</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn
            text
            color="primary"
            :disabled="!isReportAvailable(selectedProject)"
            x-large
            :to="
              `/projects/report/${(selectedProject && selectedProject._id) ||
                'new'}`
            "
          >
            RELATÓRIO
            <v-icon>mdi-pdf-box</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn
            color="primary"
            text
            x-large
            @click="deleteProject(selectedProject._id)"
            :disabled="!selectedProject"
          >
            Remover
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
import Confirm from "../../components/custom/HConfirm";

export default {
  components: { Confirm },
  created() {
    this.$nextTick(function() {
      this.refresh();
    });
  },
  mounted() {
    this.$nextTick(function() {
      this.refresh();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$nextTick(function() {
      this.refresh();
    });
    next();
  },

  data() {
    return {
      drawer: false,
      showPicker: false,
      selectedProject: null,
      jsonData: {},
      projectList: [],
      headers: [
        {
          text: "Nome do Projeto",
          value: "titulo"
        },
        {
          text: "Ano",
          value: "ano"
        },
        {
          text: "Proponente",
          value: "proponente"
        },
        {
          text: "Segmento",
          value: "segmentoNome"
        },
        {
          text: "Código chave",
          value: "_id"
        }
      ]
    };
  },
  methods: {
    rowClick(value) {
      if (value?._id) {
        this.selectedProject = value;
        this.showPicker = true;
      }
    },
    goBack() {
      this.showPicker = false;
      this.selectedProject = null;
    },
    createProject() {
      this.showPicker = true;
    },
    deleteProject(projectId) {
      this.$refs.confirm
        .open("Excluir projeto", "Tem certeza?", { color: "red" })
        .then(confirmado => {
          if (confirmado) {
            this.$api().then(caller => {
              caller
                .delete(`project/delete/${projectId}`)
                .then(results => {
                  this.jsonData = results.data;
                  this.refresh();
                })
                .catch(err => {
                  this.jsonData = err;
                });
            });
          }
        });
    },
    isReportAvailable(project) {
      if (!project) return false;
      return (
        project.questionnaire?.groups.every(group =>
          group?.questions?.every(question => !!question.value)
        ) ||
        (project.locais &&
          project.locais.some(local => !!local.estabelecimento))
      );
    },
    refresh() {
      this.showPicker = false;
      this.selectedProject = null;
      this.$api().then(caller => {
        caller
          .post("project/list")
          .then(results => {
            this.jsonData = results.data;
            this.projectList = results.data;
          })
          .catch(err => {
            this.jsonData = err;
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.actions--cell {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;

  @media only screen and (max-width: 640px) and (orientation: portrait) {
    column-gap: 1em;
    padding: 16px 0;
  }
}

.v-data-table-header label {
  color: var(--v-primary-base) !important;
}

@media only screen and (max-width: 640px) and (orientation: portrait) {
  .v-btn {
    background-color: #f0f0ff;
    border: 1px solid ghostwhite;
  }
}

.project-list__selected-project {
  padding: 48px 24px;
  max-width: 1024px;
  margin: 64px auto;
  display: flex;
  background-color: ghostwhite;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-list__selected-project--title {
  width: 100%;
  display: flex;
  flex: 1 1;
  justify-content: center;

  @media only screen and (max-width: 640px) and (orientation: portrait) {
    margin: 16px auto;
  }
}

.project-list__selected-project--action-selector {
  padding: 32px 24px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: 100px;
  justify-items: center;
  align-items: center;

  @media only screen and (max-width: 640px) and (orientation: portrait) {
    row-gap: 1em;
    grid-template-rows: repeat(4, 25%);
    grid-template-columns: auto;
  }
}
</style>

<style lang="css" scoped>
/deep/ tbody tr :hover {
  cursor: pointer;
}
</style>
